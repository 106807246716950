<div class="w-full p-gutter text-center">
  <img class="w-full max-w-lg mx-auto mb-6" src="assets/img/illustrations/under_constructions_1.svg">

  <h1 class="display-3 font-medium m-0">500</h1>
  <h2 class="title m-0 mt-3 max-w-lg mx-auto">Looks like we have an internal error, please try again later.</h2>

  <div class="bg-card rounded-full overflow-hidden relative ltr:pl-5 rtl:pr-5 h-12 max-w-md w-full shadow-8 mx-auto mt-6"
       fxLayout="row"
       fxLayoutAlign="start center">
    <ic-icon [icon]="icSearch" class="text-secondary" fxFlex="none" height="24px" width="24px"></ic-icon>
    <input class="border-0 h-12 outline-none ltr:pl-4 rtl:pr-4 placeholder:text-secondary bg-card"
           fxFlex="auto"
           placeholder="Search for other pages ..."
           type="text">
  </div>
</div>
