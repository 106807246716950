import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'apps/vought-app/src/environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request is for an external API
    /*if (req.url.startsWith('/api')) {
      const apiReq = req.clone({
        url: `${environment.apiUrl}${req.url}`
      });
      return next.handle(apiReq);
    }*/

    // If the URL is not an API call, don't modify the request
    return next.handle(req);
  }
}
