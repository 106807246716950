import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from '../../../core/services/notification/notification.service';
import { Subscription, timer, BehaviorSubject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'vought-app-toast-alert',
  templateUrl: './toast-alert.component.html',
  styleUrls: ['./toast-alert.component.scss']
})
export class ToastAlertComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  private toastQueue: any[] = [];
  private isToastVisible: boolean = false;

  message: any;
  showToast: boolean = false;
  timeout: number = 3600; // Toast visibility duration in milliseconds
  progress: number = 100;

  constructor(private notifService: NotificationService) { }

  ngOnInit() {
    this.subscription = this.notifService.getAlert()
      .subscribe(messageAlert => {
        if (messageAlert) {
          this.toastQueue.push(messageAlert);
          if (!this.isToastVisible) {
            this.displayNextToast();
          }
        }
      });
  }

  displayNextToast() {
    if (this.toastQueue.length > 0) {
      const nextToast = this.toastQueue.shift(); // Get next toast
      this.message = nextToast;
      this.setToastType(nextToast.type);
      this.showToast = true;
      this.isToastVisible = true;
      this.startAutoCloseTimer();
    }
  }

  setToastType(type: string) {
    switch (type) {
      case 'success':
        this.message.cssClass = 'success';
        break;
      case 'error':
        this.message.cssClass = 'danger';
        break;
      default:
        this.message.cssClass = 'info'; // Default case for other types
    }
  }

  startAutoCloseTimer() {
    const interval = 100; // Update progress every 100ms
    const progressStep = 100 / (this.timeout / interval);
  
    timer(0, interval).pipe(
      takeWhile(() => this.progress > 0),
      takeUntil(timer(this.timeout))
    ).subscribe({
      next: () => {
        this.progress -= progressStep;
        if (this.progress <= 0) {
          this.closeToast();
        }
      },
      complete: () => {
        this.closeToast();
      }
    });
  }

  closeToast() {
    this.showToast = false;
    this.isToastVisible = false;
    this.progress = 100; // Reset progress for the next toast
    this.displayNextToast(); // Show next toast in the queue
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
