import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Error404Component} from "./errors/error-404/error-404.component";
import {Error500Component} from "./errors/error-500/error-500.component";
import { Error403Component } from './errors/error403/error403.component';

const routes: Routes = [
  {
    path: '404',
    component: Error404Component,
    data: {
      containerEnabled: true,
      toolbarShadowEnabled: true
    }
  },
  {
    path: '400',
    component: Error500Component,
    data: {
      containerEnabled: true,
      toolbarShadowEnabled: true
    }
  },
  {
    path: '403',
    component: Error403Component,
    data: {
      containerEnabled: true,
      toolbarShadowEnabled: true
    }
  }

  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule {
}
