import { Injectable } from '@angular/core';
import { MesssageApiService } from '../api/messsage.api.service';
import { MessageState } from '../state/messages.state';
import { MessageModel } from '../model/message.model';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Pageable } from '../../../shared/interfaces/pageable';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MessageFacade {
  constructor(
    private messsageApiService: MesssageApiService,
    private messageState: MessageState
  ) {}

  getPageableParamsMessages() {
    return this.messageState.getTableParamsMessages();
  }
  setPageableParamsMessages(data: any) {
    this.messageState.setTableParamsMessage(data);
  }

  getMessages():  Observable<Pageable<MessageModel>> {
    const param:HttpParams = this.getPageableParamsMessages();

    return this.messsageApiService.getMessages(param).pipe(
      tap((response) => this.messageState.setMessages(response)),
      catchError((error) => throwError(error))
    );
  }

  createMessage(message: MessageModel): Observable<MessageModel> {
    return this.messsageApiService.createMessage(message).pipe(
      tap((newMessage) => {this.messageState.addMessage(newMessage)
      console.log('newMessage', newMessage);

      },
      catchError((error) => throwError(error)))
    );
  }

  deleteMessage(id: string) {
    return this.messsageApiService.deleteMessage(id).pipe(
      tap(() => this.messageState.removeMessage(id)),
      catchError((error) => throwError(error))
    );
  }

  updateMessage(id: string, message: MessageModel): Observable<MessageModel> {
    return this.messsageApiService.updateMessage(id, message).pipe(
      tap((updatedMessage) => this.messageState.updateMessage(updatedMessage)),
      catchError((error) => throwError(error))
    );
  }

  getActiveMessages(): Observable<MessageModel[]> {
    return this.messsageApiService.getActiveMessages().pipe(
      tap((response) => {
        this.messageState.setActiveMessages(response)
        
      }
    ),
      catchError((error) => throwError(error))
    );
  }

  // for padding if message enable 
    isMessagesEnable(): Observable<boolean> {
    return this.messageState.getExist();
    }
}