<div class="w-full p-gutter text-center">
  <img class="w-full max-w-lg mx-auto mb-6" src="assets/img/illustrations/access_denied.svg" alt="Access Denied">

  <h1 class="display-3 font-medium m-0">403</h1>
  <h2 class="headline m-0">Accès refusé.</h2>
  <p class="subheadline m-0">Vous n'avez pas le droit d'accéder à cette page.</p>

  <div class="bg-card rounded-full overflow-hidden relative ltr:pl-5 rtl:pr-5 h-12 max-w-md w-full shadow-8 mx-auto mt-6"
       fxLayout="row"
       fxLayoutAlign="start center">
    <ic-icon [icon]="icSearch" class="text-secondary" fxFlex="none" height="24px" width="24px"></ic-icon>
    <input class="border-0 h-12 outline-none ltr:pl-4 rtl:pr-4 placeholder:text-secondary bg-card"
           fxFlex="auto"
           placeholder="Rechercher d'autres pages ..."
           type="text">
  </div>

  <button mat-raised-button color="warn" class="mt-6" (click)="logout()">Déconnexion</button>
</div>