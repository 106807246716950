import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageModel } from '../model/message.model';
import { Pageable } from '../../../shared/interfaces/pageable';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MesssageApiService {
  readonly API = '/api/messages';



  constructor(private http: HttpClient) { }

  getMessages(params: HttpParams ) : Observable<Pageable<MessageModel>>{
    return this.http.get<Pageable<MessageModel>>(`${this.API}`,{params:params } ).pipe(
    );
  }
  createMessage(message: MessageModel) {
    return this.http.post<MessageModel>(this.API, message);
  }
  deleteMessage(id: string) {
    return this.http.delete(`${this.API}/${id}`);
  }
  updateMessage(id: string, message: MessageModel) {
    return this.http.put<MessageModel>(`${this.API}/${id}`, message);
  }
  getActiveMessages() {
    return this.http.get< MessageModel[] >(`${this.API}/active`).pipe(
    );
  }

 }
