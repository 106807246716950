export const labels = {
  authConfirm: {
    title: 'Confirmation',
  },
  orders: {
    title: 'Mes Commandes',
    subTitle: 'Liste de mes commandes',
    tags: 'Étiquettes',
    product: 'Produit',
  },
  cancelCommande: {
    title: 'Confirmation d\'annulation',
  },
  importTurnover: {
    title: 'Importation des factures',
  },
  importExpense: {
    title: 'Importation des dépenses',
  },
  expenseChart: {
    title: 'Dépenses par date',
    title2: 'Total des dépenses',
    description: 'Visualisez vos dépenses par date sur une période donnée.',
    description2: 'Les détails des dépenses fournissent des informations détaillées sur vos dépenses par date.',
  },
  turnoverChart: {
    title: "Analyse des Chiffres d'affaires",
    title2: "Chiffre d\'affaires",
    description: 'Consultez les chiffres d\'affaires générés.',
    description2: 'Les détails des chiffres d\'affaires fournissent des informations détaillées sur les chiffres d\'affaires.',
    byDate: 'Chiffre d\'affaires par date',
  },
  importCustomer: {
    title: 'Importation des clients',
  },
}
