import {BehaviorSubject, Observable} from "rxjs";
import {UserModel} from "../../../features/authentification/model/user.model";
import {Router} from "@angular/router";
import {map, tap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {TokenStorageService} from "../token-storage/token-storage.service";
import { CognitoService } from "./cognitoAuth/cognito.service";
import Auth from "@aws-amplify/auth";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public readonly HOME_PATH = '/home';
  public readonly LOGIN_PATH = '/auth/login';
  public readonly CONFIRM_PATH = '/auth/confirm';
  public readonly CHANGEPASSWORD_PATH = '/auth/change-password';
  public readonly CONFIMACOUNT_PATH = '/auth/confirm-account';  


  public readonly PIPELINE_PATH = '/home';

  private user$ = new BehaviorSubject<UserModel>(null);
  private emailSending$ = new BehaviorSubject<boolean>(false);
  private passwordChanging$ = new BehaviorSubject<boolean>(false);

  loggedIn$: Observable<boolean>;
  loggedOut$: Observable<boolean>;


  constructor(private router: Router, private tokenStorage: TokenStorageService, 
    private cognitoService: CognitoService
   ) {
    this.tokenStorage.getUser().subscribe((user: UserModel) => {
      this.user$.next(user);
    });
    this.loggedIn$ = this.user$.pipe(map(user => !!user))
    this.loggedOut$ = this.loggedIn$.pipe(map(loggedIn => !loggedIn));
    this.tokenStorage.getUser().pipe(tap((user: UserModel) => this.user$.next(user)));

  }
  logoutAndRedirectToLogin() {
    this.user$.next(null);
    this.tokenStorage.signOut()
    this.router.navigate([this.LOGIN_PATH]);
  }

  setUser(user: any) {
    if(!user.signInUserSession.idToken.payload['cognito:groups']){
      throw new Error("UserHasNoRole");
    }
    
      const userModel: UserModel = {
        id: user.attributes.sub || user.attributes.email,
        firstName: user.attributes.given_name || null,
        lastName: user.attributes.family_name || null,
        username: user.email || null,
        phone_number: user.attributes.phone_number || null,
        email: user.attributes.email || null,
        role: user.signInUserSession.idToken.payload['cognito:groups']? user.signInUserSession.idToken.payload['cognito:groups'][0]: null,
        signInUserSession: user.signInUserSession || null,

      };
      this.tokenStorage.saveUser(userModel);
      this.user$.next(userModel);  
  
  
  
  }
  getUserRole() {
    return this.user$.value.role;
  }


  setTemporarilyUser(user: any) {
    this.user$.next(user);
  }

  isLoggedIn$() {
    return this.loggedIn$
  }


  public get currentUser(): UserModel {
    return this.user$.value;
  }

  isEmailSending$() {
    return this.emailSending$.asObservable();
  }

  setEmailSending(isEmailSending: boolean) {
    this.emailSending$.next(isEmailSending);
  }

  isPasswordChanging$() {
    return this.passwordChanging$.asObservable();
  }

  setPasswordChanging(isPasswordChanging: boolean) {
    this.passwordChanging$.next(isPasswordChanging);
  }
  async refreshToken() {
    try {      
      const user = await Auth.currentAuthenticatedUser();
      this.setUser(user);
    } catch (error) {
      this.logoutAndRedirectToLogin();
    
    }}
}
