import { matSelectAnimations } from "@angular/material/select";

export const messages = {
  authConfirm: {
    msg0001: "Votre compte a été confirmé avec succès !",
    msg0002: "Retour à la page de votre espace",
    msg0003: "Votre mot de passe a été modifié avec succès !",
    msg0004: "Retour à la page d'identification",
  },
  authLogin: {
    msg0001: "Email ou mot de passe incorrect !!",
    msg0002: 'login success !!',
    msg0003 : "Email n'est pas trouvé.",
    msg0004: "Mot de passe incorrect.",
    msg0005: "Votre compte e  st désactivé, ou vous n'avez pas les droits d'accès" ,
    msg0006: "Votre compte n'est pas confirmé",
    msg0007: "Votre compte n'a pas de rôle",

  },
  authChangePassword: {
    msg0001: "Le code de validation est incorrect.",
    msg0002: "Le code de validation a expiré.",
    msg0003: "Le mot de passe ne respecte pas les règles de sécurité.",
    msg0004: "Le nombre de tentatives a été dépassé. Veuillez réessayer plus tard.",
    msg0005: "Une erreur est survenue lors de la validation du code."
  },
  authConfirmAccount: {
    msg0001: "La demande de réinitialisation de mot de passe n'est plus valable ! Veuillez refaire une autre demande.",
    msg0002: "L'e-mail est incorrect !",
    msg0003: "Une erreur est survenue lors de la validation du code.",
    msg0004: "Le mot de passe doit contenir au moins 8 caractères, dont une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.",
    msg0005: "Le mot de passe doit être différent du mot de passe précédent.",
    msg0006: "Votre compte nécessite une validation du responsable.",
    msg0007: "Une erreur est survenue lors de la réinitialisation du mot de passe.",
    msg0008: "Le nombre de tentatives a été dépassé. Veuillez réessayer plus tard.",
    msg0009: "Le numéro de téléphone d'utilisateur est manquant",
    msg0010: "Le Mot de passe a été modifié avec succès, mais l'utilisateur n'a pas de rôle",
  },
  authResetPwd: {
    msg0001: "Une erreur s'est produite lors de l'envoi de l'e-mail de réinitialisation !",
    msg0002: "L'e-mail de récupération est incorrect !",
    msg0003: "L'e-mail doit être confirmé par l'administrateur.",
    msg0004: "L'utilisateur n'existe pas.",
    msg0005: "Le nombre de tentatives a été dépassé. Veuillez réessayer plus tard."
  },
  importTurnover: {
    msg0001: "Cliquez sur le bouton pour sélectionner un fichier.",
    msg0002: "Le fichier doit être de type Excel (xlsx, xls) .",
    msg0003: "Assurez-vousque les en-têtes suivants sont présents :",
    msg0004: "Assurez-vous que les en-têtes suivants sont présents : Date de Service, Employé, Client, Chiffre d'Affaires (HT), Statut de Paiement",
    msg0005: "Fichier valide",
    msg0006: "Fichier contient des erreurs",
    msg0007: "Fichier invalide \n",
    msg0008: "Erreur de validation du fichier",
    msg0009: "Impossible de valider le fichier. format invalide ou chargement en cours.",
    msg0010: "Erreur de soumission du fichier",
    msg0011: "La facture est invalide : ",
    msg0012: "La référence de la facture est déjà utilisée : ",
    msg0013: "Entité non trouvée : ",
    msg0014: "Le fichier contient des erreurs. Veuillez corriger les erreurs et soumettre à nouveau.",
    msg0015: "Le fichier a été soumis avec succès.",
    
  },
  importExpense: {
    msg0001: "Cliquez sur le bouton pour sélectionner un fichier.",
    msg0002: "Le fichier doit être de type Excel (xlsx, xls) .",
    msg0003: "Assurez-vousque les en-têtes suivants sont présents :",
    msg0004: "Assurez-vous que les en-têtes suivants sont présents : DateExpense, Amount, ExpenseCategory, Description, EmailUser.",
    msg0005: "Fichier invalide",
    msg0006: "Fichier contient des erreurs",
    msg0007: "Fichier invalide \n",
    msg0008: "Erreur de validation du fichier",
    msg0009: "Impossible de valider le fichier. format invalide ou chargement en cours.",
    msg0010: "Erreur de soumission du fichier",
    msg0011: "Dépense invalide : ",
    msg0012: "La ligne avec la méme date et email  est déjà utilisée : ",
    msg0013: "Entité non trouvée : ",
    msg0014: "Le fichier contient des erreurs. Veuillez corriger les erreurs et soumettre à nouveau.",
    msg0015: "Le fichier a été soumis avec succès.",
  
  },
  turnoverList: {
    msg0001: "Le fichier a été soumis avec succès.",
    msg0002: "Le changement de statut a été effectué avec succès.",
    msg0003: "Le chiffre d'affaire a été supprimé avec succès.",
    msg0004: "Le chiffre d'affaire a été modifié avec succès.",
    msg0005: "Le chiffre d'affaire a été ajouté avec succès.",
    msg0006: "Erreur lors de la suppression du chiffre d'affaire.",
    msg0007: "Erreur lors de la modification du chiffre d'affaire.",
    msg0008: "Erreur lors de l'ajout du chiffre d'affaire.",
    msg0009: "Erreur lors du changement de statut.",
    msg0010: "Erreur lors de la récupération des chiffres d'affaire.",
    
  },
  deleteTurnover: {
    msg0001: "Voulez-vous vraiment supprimer le chiffre d'affaire ?",
    msg0002: "Cette action est irréversible.",
    msg0003: "Supprimer le chiffre d'affaire",
    msg0004: "Suppression du chiffre d'affaire",
  },

  deleteCustomer: {
    msg0001: "Vous êtes entrain de désactiver le client",
    msg0002: "La suppression du client est irréversible.",
    msg0003: "Voulez-vous continuer ?",
    msg0004: "La suppression du client a été effectuée avec succès",
  },
  importCustomer: {
    msg0001: 'Veuillez sélectionner un fichier à importer.',
    msg0002: 'Le fichier doit être au format Excel (.xlsx).',
    msg0003: 'Le fichier est en cours de validation...',
    msg0004: 'Les en-têtes du fichier ne correspondent pas aux en-têtes requis.',
    msg0005: 'Le fichier a été validé avec succès.',
    msg0006: 'Le fichier contient des erreurs. Veuillez corriger les erreurs et réessayer.',
    msg0009: 'Veuillez valider le format du fichier avant de le soumettre.',
    msg0010: 'Une erreur inconnue est survenue lors de la soumission du fichier.',
    msg0011: 'Le fichier contient des clients invalides : ',
    msg0012: 'Le fichier contient des ressources en double : ',
    msg0013: 'Certaines entités n\'ont pas été trouvées : ',
    msg0014: 'Erreur lors de la validation du fichier.',
    msg0015: 'Fichier soumis avec succès.'
  },
  cancelCommande: {
    msg0001: "Vous êtes entrain d'annuler la commande numéro ",
    msg0002: "Voulez-vous continuer ?",
  },
  deleteProduct: {
    msg0001: "Vous êtes entrain de supprimer le produit ",
    msg0002: "Voulez-vous continuer ?",
  }


}
