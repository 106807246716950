import {Component, Input, OnInit} from '@angular/core';
import icAdd from '@iconify/icons-ic/twotone-add';
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'vought-app-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.scss']
})
export class LabelsComponent implements OnInit {
  @Input() model: any[];
  @Input() data: any[];
  @Input() selectedLabels: any[];


  icAdd = icAdd;

  constructor() {
  }

  ngOnInit(): void {
  }

  onLabelChange(change: MatSelectChange, model: any) {
    this.selectedLabels = change.value;
  }
}
