<mat-form-field class="autocomplete flex-auto">
  <mat-label> {{title}}</mat-label>
  <input [formControl]="stateCtrl" [matAutocomplete]="auto" matInput required>
  <mat-error *ngIf="stateCtrl.hasError('required')">{{title}} est obligatoire
  </mat-error>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onChange($event)">
    <mat-option *ngFor="let state of filteredStates$ | async" [value]="state.name">
      <span class="body-1">{{ state.name }}</span>
    </mat-option>
  </mat-autocomplete>
  <button (click)="stateCtrl.reset()" *ngIf="stateCtrl.value" mat-icon-button matSuffix type="button">
    <mat-icon [icIcon]="icClose" [inline]="true" size="24px"></mat-icon>
  </button>
  <button *ngIf="!stateCtrl.value" mat-icon-button matSuffix type="button">
    <mat-icon [icIcon]="icArrowDropDown" [inline]="true" size="24px"></mat-icon>
  </button>
</mat-form-field>
