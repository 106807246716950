import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CoreRoutingModule} from './core-routing.module';
import {ForRolesDirective} from "../shared/directives/for-roles/for-roles.directive";
import {LayoutModule} from "../../../../../libs/@vex/layout/layout.module";
import {SidenavModule} from "../../../../../libs/@vex/layout/sidenav/sidenav.module";
import {ToolbarModule} from "../../../../../libs/@vex/layout/toolbar/toolbar.module";
import {FooterModule} from "../../../../../libs/@vex/layout/footer/footer.module";
import {ConfigPanelModule} from "../../../../../libs/@vex/components/config-panel/config-panel.module";
import {SidebarModule} from "../../../../../libs/@vex/components/sidebar/sidebar.module";
import {QuickpanelModule} from "../../../../../libs/@vex/layout/quickpanel/quickpanel.module";
import {CustomLayoutComponent} from "./main-layout/custom-layout.component";
import {FlexLayoutModule} from "@angular/flex-layout";
import {IconModule} from "@visurel/iconify-angular";
import {Error404Component} from "./errors/error-404/error-404.component";
import {Error500Component} from "./errors/error-500/error-500.component";
import {DecimalNumberDirective} from '../shared/directives/decimal-number/decimal-number.directive';
import { Error403Component } from './errors/error403/error403.component';


@NgModule({
  declarations: [
    CustomLayoutComponent,
    ForRolesDirective,
    Error404Component,
    Error500Component,
    Error403Component,
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    LayoutModule,
    SidenavModule,
    ToolbarModule,
    FooterModule,
    ConfigPanelModule,
    SidebarModule,
    QuickpanelModule,
    FlexLayoutModule,
    IconModule
  ], exports: [
    /** Directives**/
    ForRolesDirective,

    /** Components**/
    Error404Component,
    Error500Component
  ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error("You should import core module only in the root module")
    }
  }
}
