import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from "../../../../libs/@vex/services/config.service";
import { Style, StyleService } from "../../../../libs/@vex/services/style.service";
import { Platform } from "@angular/cdk/platform";
import { LayoutService } from "../../../../libs/@vex/services/layout.service";
import { ActivatedRoute } from "@angular/router";
import { NavigationService } from "../../../../libs/@vex/services/navigation.service";
import { SplashScreenService } from "../../../../libs/@vex/services/splash-screen.service";
import { Settings } from "luxon";
import { DOCUMENT } from "@angular/common";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { filter, map } from "rxjs/operators";
import { ConfigName } from "../../../../libs/@vex/interfaces/config-name.model";
import icShoppingCart from '@iconify/icons-ic/twotone-shopping-cart';
import icAccountBalance from '@iconify/icons-ic/twotone-account-balance';
import icPeople from '@iconify/icons-ic/people';
import icWarehouse from '@iconify/icons-ic/twotone-warehouse';
import icMessage from '@iconify/icons-ic/message';
import icTodo from '@iconify/icons-ic/twotone-assignment';
import icSetting from '@iconify/icons-ic/settings';
import descriptionIcon from '@iconify/icons-ic/description';
import { RolesEnum } from './shared/enums/roles.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vought-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'vought-app';

  constructor(
    private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private translate: TranslateService,
  ) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))),
    ).subscribe(isRtl => {
      this.document.body.dir = isRtl ? 'rtl' : 'ltr';
      this.configService.updateConfig({
        rtl: isRtl
      });
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));

    // Load translations and initialize navigation
    this.translate.get([
      'modules.navigation'
    ]).subscribe(translations => {
      this.initializeNavigation();
    });
  }
  private initializeNavigation() {
    this.navigationService.items = [
      {
        type: 'link',
        label: this.translate.instant('modules.navigation.home'),
        route: 'home',
        icon: icAccountBalance,
        permissions: ['COM', RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER, RolesEnum.PORTAGE_EMPLOYEE, RolesEnum.REGULAR_EMPLOYEE],
      },
      {
        type: 'link',
        label: this.translate.instant('modules.navigation.admin'),
        route: 'admin',
        icon: icWarehouse,
        permissions: ['COM', RolesEnum.SUPER_ADMIN],
      },
      {
        type: 'dropdown',
        label: this.translate.instant('modules.navigation.clients.label'),
        icon: icPeople,
        permissions: ['COM', RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER],
        children: [
          {
            type: 'link',
            label: this.translate.instant('modules.navigation.clients.import'),
            permissions: ['COM', RolesEnum.ADMIN, RolesEnum.FINANCIER],
            route: 'customers/import',
          },
          {
            type: 'link',
            label: this.translate.instant('modules.navigation.clients.myClients'),
            permissions: ['COM', RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER],
            route: 'customers',
          },
        ]
      },
      {
        type: 'dropdown',
        label: this.translate.instant('modules.navigation.turnover.label'),
        icon: icAccountBalance,
        permissions: ['COM', RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER],
        children: [
          {
            type: 'link',
            label: this.translate.instant('modules.navigation.turnover.import'),
            permissions: ['COM', RolesEnum.ADMIN, RolesEnum.FINANCIER],
            route: 'turnover/import',
          },
          {
            type: 'link',
            label: this.translate.instant('modules.navigation.turnover.list'),
            permissions: [RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER],
            route: 'turnover/list',
          }
        ]
      },
      {
        type: 'dropdown',
        label: this.translate.instant('modules.navigation.expenses.label'),
        icon: icShoppingCart,
        permissions: ['COM', RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER, RolesEnum.PORTAGE_EMPLOYEE, RolesEnum.REGULAR_EMPLOYEE],
        children: [
          {
            type: 'link',
            label: this.translate.instant('modules.navigation.expenses.addExpense'),
            permissions: ['COM', RolesEnum.PORTAGE_EMPLOYEE, RolesEnum.REGULAR_EMPLOYEE],
            route: 'expense/add-expense'
          },
    
          {
            type: 'link',
            label: this.translate.instant('modules.navigation.expenses.import'),
            permissions: ['COM', RolesEnum.ADMIN, RolesEnum.FINANCIER],
            route: 'expense/import'
          },
          {
            type: 'link',
            label: this.translate.instant('modules.navigation.expenses.history'),
            permissions: ['COM', RolesEnum.PORTAGE_EMPLOYEE, RolesEnum.REGULAR_EMPLOYEE],
            route: 'expense/history'
          },
          {
            type: 'link',
            label: this.translate.instant('modules.navigation.expenses.requests'),
            permissions: ['COM', RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER],
            route: 'expense/requests'
          }
        ]
      },
      {
        type: 'link',
        label: this.translate.instant('modules.navigation.messages'),
        route: 'messages',
        icon: icMessage,
        permissions: ['COM', RolesEnum.ADMIN],
      },
      {
        type: 'dropdown',
        label: this.translate.instant('modules.navigation.tasks.label'),
        icon: icTodo,
        permissions: ['COM', RolesEnum.ADMIN, RolesEnum.FINANCIER, RolesEnum.PORTAGE_EMPLOYEE, RolesEnum.REGULAR_EMPLOYEE],
        children: [
          {
            type: 'link',
            label: this.translate.instant('modules.navigation.tasks.board'),
            route: 'tasks/board',
            icon: icTodo,
            permissions: ['COM', RolesEnum.ADMIN, RolesEnum.FINANCIER, RolesEnum.PORTAGE_EMPLOYEE, RolesEnum.REGULAR_EMPLOYEE],
          },
          {
            type: 'link',
            label: this.translate.instant('modules.navigation.tasks.list'),
            route: 'tasks/list',
            icon: icTodo,
            permissions: ['COM', RolesEnum.ADMIN, RolesEnum.FINANCIER, RolesEnum.PORTAGE_EMPLOYEE, RolesEnum.REGULAR_EMPLOYEE],
          }
        ]
      },
      {
        type: 'link',
        label: this.translate.instant('modules.navigation.contracts'),
        icon: descriptionIcon,
        permissions: ['COM', RolesEnum.ADMIN, RolesEnum.FINANCIER, RolesEnum.SUPER_ADMIN],
        route: 'contract',
      },
      {
      type: 'dropdown',
      label: this.translate.instant('modules.navigation.settings.label'),
      icon: icSetting,
      permissions: ['COM', RolesEnum.ADMIN, RolesEnum.FINANCIER, RolesEnum.SUPER_ADMIN],
      children: [
        {
          type: 'link',
          label: this.translate.instant('modules.navigation.settings.expenseCategory'),
          permissions: ['COM', RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER],
          route: 'expense/categories'
        },
        {
          type: 'link',
          label: this.translate.instant('modules.navigation.settings.paymentTerms'),
          route: 'contract/payment-terms',
          icon: descriptionIcon,
          permissions: ['COM', RolesEnum.ADMIN, RolesEnum.FINANCIER, RolesEnum.SUPER_ADMIN ],
        }
      
      ]
    }
    ];
  }
}