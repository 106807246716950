import { Component, OnInit } from '@angular/core';
import icSearch from '@iconify/icons-ic/twotone-search';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'vought-app-error403',
  templateUrl: './error403.component.html',
  styleUrls: ['./error403.component.scss']
})
export class Error403Component implements OnInit {

  constructor(private authService:AuthService ) { 

  }
  icSearch = icSearch;


  ngOnInit(): void {
  }
  logout(): void {
    this.authService.logoutAndRedirectToLogin();
  }

}
