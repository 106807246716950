import {Injectable} from "@angular/core";
import {AuthService} from "../services/auth/auth.service";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError, from} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {Router} from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError(error => {
      if (error.status === 401) {
        return from(this.authService.refreshToken()).pipe(
          switchMap(() => {
            const clonedRequest = request.clone({
              setHeaders: {
                Authorization: `Bearer ${this.authService.currentUser.signInUserSession.idToken.jwtToken}`
              }
            });
            return next.handle(clonedRequest);
          }),
          catchError(err => {
           this.authService.logoutAndRedirectToLogin();
            return throwError(err);
          })
        );
      }

       if (error.status === 500 || error.status === 502 || error.status === 503) {
        this.router.navigate(['errors/500']);
      }
      if (error.status === 403) {
        this.router.navigate(['errors/403']);
      }
      return throwError(error);
    }));
  }


}
