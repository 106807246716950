import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from "../../../core/services/auth/auth.service";


@Directive({
  selector: '[erpBtnForRoles]'
})
export class ForRolesDirective {

  roles: string[];

  @Input()
  set erpBtnForRoles(roles: string[] | string) {
    if (roles != null) {
      this.roles = Array.isArray(roles) ? roles : [roles];
      this.roles = this.roles.map(r => r.toUpperCase());
    } else {
      this.roles = [];
    }

    const role = this.authService.currentUser.role;
    if (role && !this.roles.includes(role.toUpperCase())) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }

  }

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: AuthService) {
  }

}
