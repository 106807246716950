import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[erpBtnDecimalNumber]'
})
export class DecimalNumberDirective {

  private el: HTMLInputElement;
  @Input() erpBtnDecimalNumber: string;
  private value: any;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus() {
    this.el.value = this.value;
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value: any) {
    this.transform(value);
  }

  ngOnInit() {
    this.transform(this.el.value);
  }

  transform(value: any) {
    this.value = value;
    if (value && !isNaN(value)) {
      const aux = "" + Math.round(+this.value * 100);
      this.el.value = aux.slice(0, -2) + "." + aux.slice(-2);
      if (this.el.value.indexOf(this.value) >= 0) this.value = this.el.value;
    }
  }


}
