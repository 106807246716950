<div *ngIf="showToast" class="toast-container" >
    <div class="toast" [ngClass]="message.cssClass">
      <div class="toast-icon">
        <i *ngIf="message.type === 'success'" class="fas fa-check-circle"></i>
        <i *ngIf="message.type === 'error'" class="fas fa-exclamation-circle"></i>
      </div>
      <div class="toast-content">
        <strong>{{ message.text }}</strong>
      </div>
      <div class="toast-progress">
        <div class="progress-bar" [style.width]="progress + '%'"></div>
      </div>
    </div>
  </div>
  