export const StatusList = [

  {
    id: 1,
    name: 'NEW_OPPORTUNITY',
    label: 'NOUVEAU',
    textClass: 'text-green',
    bgClass: 'bg-green-light',
    previewClass: 'bg-green'
  },
  {
    id: 2,
    name: 'QUALIFIED',
    label: 'QUALIFIER',
    textClass: 'text-cyan',
    bgClass: 'bg-cyan-light',
    previewClass: 'bg-cyan'
  },
  {
    id: 3,
    name: 'NEW_PROP',
    label: 'PROPOSITION',
    textClass: 'text-teal',
    bgClass: 'bg-teal-light',
    previewClass: 'bg-teal'
  },
  {
    id: 4,
    name: 'PROP_GAINED',
    label: 'GAGNEE',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 5,
    name: 'PROP_LOST',
    label: 'PERDUE',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 6,
    name: 'PROP_STAND_BY',
    label: 'TEMPORISEE',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 7,
    name: 'PROP_DECLINED',
    label: 'REJETEE',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 8,
    name: 'NEW_CMD',
    label: 'NOUVELLE',
    textClass: 'text-green',
    bgClass: 'bg-green-light',
    previewClass: 'bg-green'
  },
  {
    id: 9,
    name: 'CMD_WAITING_CTRL',
    label: 'EN ATTENT DE CONTRÔLE',
    textClass: 'text-cyan',
    bgClass: 'bg-cyan-light',
    previewClass: 'bg-cyan'
  },
  {
    id: 10,
    name: 'CMD_CTRL_OK',
    label: 'VALIDÉE PAR LE CONTRÔLEUR',
    textClass: 'text-teal',
    bgClass: 'bg-teal-light',
    previewClass: 'bg-teal'
  },
  {
    id: 11,
    name: 'CMD_CTRL_KO',
    label: 'REJETÉE PAR LE CONTRÔLEUR',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 12,
    name: 'CANCELED_CMD',
    label: 'ANNULEE',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 13,
    name: 'CMD_WAITING_CLIENT',
    label: 'EN ATTENT DU CLIENT',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 14,
    name: 'CMD_CLIENT_OK',
    label: 'VALIDÉE PAR LE CLIENT',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 15,
    name: 'BDC_EDITED',
    label: 'BDC ÉDITÉ',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 16,
    name: 'BDC_DECLINED',
    label: 'BDC REFUSÉ',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 17,
    name: 'BDC_SIGNED',
    label: 'BDC SIGNÉ',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 18,
    name: 'START_PROD',
    label: 'PRODUCTION COMMENCÉE',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 19,
    name: 'STOCK_KO',
    label: 'STOCK INSUFFISANT',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 20,
    name: 'STOCK_OK',
    label: 'STOCK SUFFISANT',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 21,
    name: 'TRUCK_AFFECTED',
    label: 'CAMION AFFECTÉ',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 22,
    name: 'START_MANUFACTURING',
    label: 'FABRICATION COMMENCÉE',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 23,
    name: 'END_MANUFACTURING',
    label: 'FABRICATION TERMINÉE',
    textClass: 'text-purple',
    bgClass: 'bg-purple-light',
    previewClass: 'bg-purple'
  },
  {
    id: 24,
    name: 'DELIVERY_IN_PROGRESS',
    label: 'LIVRAISON EN COURS',
    textClass: 'text-orange',
    bgClass: 'bg-orange-light',
    previewClass: 'bg-orange'
  },
  {
    id: 25,
    name: 'DELIVERY_DONE',
    label: 'LIVRAISON FAITE',
    textClass: 'text-green',
    bgClass: 'bg-green-light',
    previewClass: 'bg-green'
  },
  {
    id: 26,
    name: 'PAID',
    label: 'PAYÉ',
    textClass: 'text-green',
    bgClass: 'bg-green-light',
    previewClass: 'bg-green'
  },
  {
    id: 27,
    name: 'UNPAID',
    label: 'NON PAYÉ',
    textClass: 'text-red',
    bgClass: 'bg-red-light',
    previewClass: 'bg-red'
  },
];
