import {Injectable} from '@angular/core';
import {
  NavigationDropdown,
  NavigationItem,
  NavigationLink,
  NavigationSubheading
} from '../interfaces/navigation-item.interface';
import {Subject, using} from 'rxjs';
import {AuthService} from "../../../apps/vought-app/src/app/core/services/auth/auth.service";
import {TokenStorageService} from "../../../apps/vought-app/src/app/core/services/token-storage/token-storage.service";
import {UserModel} from "../../../apps/vought-app/src/app/features/authentification/model/user.model";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  user: UserModel;
  items: NavigationItem[] = [];
  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();


  constructor(private authService: AuthService) {
  }

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }


  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }

  hasPermission(item: NavigationItem) {
    const user = this.authService.currentUser;
    if(!user){
      return false
    }
      return item.permissions.indexOf(user.role) !== -1;
  }

}
