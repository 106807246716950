import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MessageFacade } from '../../../features/messages/facade/messages.facade';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../core/services/auth/auth.service';
import { RolesEnum } from '../../enums/roles.enum';

@Component({
  selector: 'vought-app-message-ticker',
  templateUrl: './message-ticker.component.html',
  styleUrls: ['./message-ticker.component.scss']
})
export class MessageTickerComponent implements OnInit, OnDestroy {
  concatenatedMessagesArray: string[] = [];
  private subscription: Subscription = new Subscription();
  isVisible: boolean = false;

  constructor(
    private messageFacade: MessageFacade,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.checkRoute();
      })
    );

    this.checkRoute();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private checkRoute(): void {
      const role = this.authService.getUserRole();
        if (role != RolesEnum.SUPER_ADMIN) {
          this.isVisible = true;
          this.subscription.add(
            this.messageFacade.getActiveMessages()
              .subscribe(activeMessages => {
                this.concatenatedMessagesArray = activeMessages.map(message => message.content);
                if(this.concatenatedMessagesArray.length === 0){
                  this.isVisible = false;
                }
              })
          );
        }
        else{
          this.isVisible = false;
        }
      
     
    
    
    
  }
}