import {Injectable} from "@angular/core";
import {AuthService} from "../services/auth/auth.service";
import {HttpEvent, HttpHandler, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable()
export class JwtInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authService.currentUser;
    if (currentUser) {
      // append token to the request header if user is logged in
      const token = currentUser.signInUserSession.idToken.jwtToken;
      request = this.addToken(request, token);
    }
    return next.handle(request);
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {'Authorization': `Bearer ${token}`,
    }
    });
  }
}
