import { Injectable } from '@angular/core';
import {of} from "rxjs";

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  signOut(): void {
   localStorage.clear();
  }

  public saveToken(token: string): void {
   localStorage.removeItem(TOKEN_KEY);
   localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user): void {
   localStorage.removeItem(USER_KEY);
   localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    return of(JSON.parse(localStorage.getItem(USER_KEY)));
  }
}
