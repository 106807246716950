import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Tenant } from "../../admin/model/tenant.model";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
  })

export class ProfileApiService {
    private apiUrl = '/api';

    constructor(private http: HttpClient) {}

    getProfileTenant(): Observable<Tenant> {
        return this.http.get<Tenant>(`${this.apiUrl}/tenant/current`);
    }
}