<vex-page-layout>

  <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
    <div [class.container]="layoutCtrl.value === 'boxed'"
         [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
         class="w-full flex flex-col sm:flex-row justify-between">
      <div>
        <h1 class="title mt-0 mb-1">{{title}}</h1>
      </div>


    </div>
  </vex-page-layout-header>

  <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
                           [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
                           class="-mt-6">

    <div class="card overflow-auto -mt-16">


      <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Checkbox Column -->
        <ng-container matColumnDef="checkbox">
          <th *matHeaderCellDef mat-header-cell>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          color="primary">
            </mat-checkbox>
          </th>
          <td *matCellDef="let row" class="w-4" mat-cell>
            <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                          (click)="$event.stopPropagation()"
                          [checked]="selection.isSelected(row)"
                          color="primary">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Image Column -->
        <ng-container matColumnDef="image">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
            <img [src]="row['imageSrc']" class="avatar h-8 w-8 align-middle">
          </td>
        </ng-container>

        <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">

          <ng-container *ngIf="column.type === 'tag'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" mat-cell>
              <div (click)="$event.stopPropagation()" fxLayoutAlign="start center" fxLayoutGap="4px">
                <div
                  [ngClass]="[getTag(row[column.property]).textClass, getTag(row[column.property]).bgClass]"
                  class="rounded px-2 py-1 font-medium text-xs"
                  fxFlex="none">
                  {{ getTag(row[column.property]).label }}
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property]}}</td>
          </ng-container>
          <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses"
                mat-cell>{{ row[column.property] | date : 'longDate' }}</td>
          </ng-container>
          <ng-container *ngIf="column.type === 'amount'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }} </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses"
                mat-cell>{{ row[column.property] | number : '.2-2':'fr' }} €</td>
          </ng-container>
        </ng-container>

  

        <!-- Label Column -->
        <ng-container matColumnDef="labels">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>Étiquettes</th>
          <td *matCellDef="let row" mat-cell>
            <div (click)="$event.stopPropagation()" fxLayoutAlign="start center" fxLayoutGap="4px">
              <div *ngFor="let tag of row.tags"
                   [ngClass]="[getClassCss(tag.id).backgroundContrastColor, getClassCss(tag.id).backgroundColor]"
                   class="rounded px-2 py-1 font-medium text-xs"
                   fxFlex="none">
                {{ tag.name}}
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
          <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
            <button (click)="$event.stopPropagation()"
                    [matMenuTriggerData]="{ item: row }"
                    [matMenuTriggerFor]="actionsMenu"
                    mat-icon-button
                    type="button">
              <mat-icon [icIcon]="icMoreHoriz"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr (click)="updateItem(row)"
            *matRowDef="let row; columns: visibleColumns;"
            @fadeInUp
            class="hover:bg-hover trans-ease-out cursor-pointer"
            mat-row></tr>
      </table>
    </div>

  </vex-page-layout-content>

</vex-page-layout>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns"
          class="checkbox-item mat-menu-item">
    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
      {{ column.label }}
    </mat-checkbox>
  </button>
</mat-menu>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-item="item" matMenuContent>
    <div *ngFor="let action of rowActions">
      <button color="primary" mat-menu-item [matTooltip]="action.label" *ngIf="action.list"
              type="button"
              (click)="doAction(action,item)">
        <mat-icon [icIcon]="action.icon"></mat-icon>
        <span>{{action.label}}</span>
      </button>
    </div>

  </ng-template>
</mat-menu>

