import { Injectable } from '@angular/core';

import { environment } from '../../../../../environments/environment';

import { Amplify } from '@aws-amplify/core';
import { Auth } from "@aws-amplify/auth";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { th } from 'date-fns/locale';
import { UserModel } from '../../../../features/authentification/model/user.model';
import { A } from '@angular/cdk/keycodes';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  constructor(
    private http: HttpClient
  ) {
    Amplify.configure({
      Auth: environment.cognito
    });
  }

  public async singIn(email:string, password:string){
    
    try {
      const user = await Auth.signIn(email, password);
     return user  ;
    } catch (error) {
      console.log("error is ", error);

        throw error;
      }
    }

    public async forgotPassword(email: string): Promise<any> {
      try {
        const data = await Auth.forgotPassword(email);
        return {
          emailSending: true,
          data: data
        };
      } catch (error) {
      //  console.error('An error occurred during forgot password:', error);
        throw error;
      }
    }

    public async completeNewPassword(user: any, newPassword: string): Promise<UserModel> {
      try {     

        const user_2 = await Auth.completeNewPassword(user, newPassword);
        return user_2
      } catch (error) {
        throw error;
      }
    }

    public async   resetPassword(email: string, code: string, newPassword: string): Promise<any> {
      try {
        const data = await Auth.forgotPasswordSubmit(email, code, newPassword);
        return {
          passwordReset: true,
          data: data
        };
      } catch (error) {
   //     console.error('An error occurred during password reset:', error);
        throw error;
      }

    }

    public async signOut(): Promise<void> {
      try {
        await Auth.signOut();
      } catch (error) {
     //   console.error('An error occurred during sign-out:', error);
        throw error;
      }
    }

    verifyEmail(email: string, code: string): Promise<any> {
      return Auth.verifyCurrentUserAttributeSubmit('email', code);
    }
    sendVerificationCode(email: string): Promise<any> { 
      return Auth.verifyCurrentUserAttribute('email');
    }

   
  }

  