import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icStar from '@iconify/icons-ic/twotone-star';
import icStarBorder from '@iconify/icons-ic/twotone-star-border';
import icDescription from '@iconify/icons-ic/twotone-description';
import {Action} from "../../../../interfaces/action.interface";
import IcSharpDomainDisabled from '@iconify/icons-ic/sharp-domain-disabled';
import { ActionEnum } from 'libs/ui/src/lib/services/data.service';
@Component({
  selector: 'vought-app-element-card',
  templateUrl: './element-card.component.html',
  styleUrls: ['./element-card.component.scss']
})
export class ElementCardComponent implements OnInit {

  @Input() element: any;
  @Output() eventAction = new EventEmitter<any>();
  @Output() toggleStar = new EventEmitter<any['id']>();
  @Input() actions: Action[];



  constructor() {
  }

  ngOnInit(): void {
  }

  emitToggleStar(event: MouseEvent) {
    event.stopPropagation();
    this.toggleStar.emit({
      data: this.element,
    });
  }

  doAction(action: Action, element) {
    this.eventAction.emit({
      data: element,
      action: action.action
    })
  }
  doEnable(element) {
    this.eventAction.emit({
      data: element,
      action: ActionEnum.ENABLE_TENANT
    })
  }

  get icBusiness(): any {
    return icBusiness;
  }
  get icDisabled():any {
    return IcSharpDomainDisabled;
  }

  get icPhone(): any {
    return icPhone;
  }

  get icStar(): any {
    return icStar;
  }
  get icDescription(): any {
    return icDescription;
  }

  get icStarBorder(): any {
    return icStarBorder;
  }
}
