export const environment = {
  production: false,
  apiUrl: "https://vought-back-mewt3.ondigitalocean.app",
  cognito: {
    userPoolId: 'eu-west-3_hfrrjmW6D',
    userPoolWebClientId: '1437kccep9somfrg9sa7o44kfv',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    region: 'eu-west-3',
    scope: "email openid"
  },
};
