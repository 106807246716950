import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor() { }

  logError(message: string, stack: string) {
    //TODO Send errors to be saved here on slack
    console.log('LoggingService: ' + message);
  }
  logInfo(message: string) {
    console.log('LoggingService: ' + message);
  }
}
