<div *ngIf="board$ | async as board" class="h-full w-full overflow-hidden" fxLayout="column">

  <div class="py-4 px-gutter" fxFlex="none" fxLayout="row" fxLayoutAlign="start center" vexContainer>
    <h1 class="title m-0">{{title}}</h1>

    <span fxFlex></span>

  </div>

  <div (cdkDropListDropped)="dropList($event)"
       @stagger
       [cdkDropListData]="board.children"
       cdkDropList
       cdkDropListOrientation="horizontal"
       class="px-gutter pb-gutter min-h-0 overflow-x-auto"
       fxFlex="auto"
       fxLayout="row"
       fxLayoutAlign="start start"
       fxLayoutGap="24px"
       vexContainer>
    <div *ngFor="let list of board.children; trackBy: trackById"
         @fadeInUp
         [cdkDragData]="list"
         cdkDrag
         cdkDropListGroup
         class="rounded bg-app-bar overflow-hidden max-h-full w-full max-w-xs vw-100 "
         fxFlex="none"
         fxLayout="column">
      <div cdkDragHandle class="p-4 cursor-pointer" fxFlex="none">
        <h2 class="subheading-2 font-medium m-0 select-none" fxFlex="none">{{ list.label }}</h2>
      </div>

      <vex-scrollbar class="flex-auto">
        <div (cdkDropListDropped)="drop($event)"
             [cdkDropListConnectedTo]="getConnectedList(board)"
             [cdkDropListData]="list.children"
             cdkDropList
             class="px-4 pb-4"
             id="{{ list.id }}">
          <div
            *ngFor="let card of list.children; trackBy: trackById"
            [cdkDragData]="card"
            cdkDrag
            class="scrumboard-card card w-full cursor-pointer overflow-hidden">
            <div (click)="open(board, list, card)">
              <div *ngIf="card.cover" class="w-full h-40 overflow-hidden">
                <img [src]="card.cover.path" class="object-cover w-full h-full">
              </div>

              <div class="p-4">
                <div class="mb-2" fxLayout="row" fxLayoutGap="8px">
                  <div *ngIf="card.priority"
                       [ngClass]="card.priority.bgClass"
                       class="label"
                       fxFlex></div>
                </div>

                <h3 class="body-1 m-0 select-none">{{ card.customer.name }}</h3>
                <h3 class="body-2 m-0 select-none">{{ card.title }}</h3>
                <span class="box-text"> Client :{{ card.description }}</span>
                <div
                  class="mt-2"
                  fxLayout="row"
                  fxLayoutGap="8px">
                  <div *ngIf="card.priority"
                       [ngClass]="card.priority.bgClass"
                       class="box bg-app-bar text-secondary rounded caption">

                    <span class="box-text">Priorité :{{ card.priority.label}}</span>
                  </div>
                </div>
                <div
                  class="mt-2"
                  fxLayout="row"
                  fxLayoutGap="8px">
                  <div *ngIf="card.deadlineDate"
                       [ngClass]="{ 'bg-green text-contrast-white': card.deadlineDate }"
                       class="box bg-app-bar text-secondary rounded caption">
                    <ic-icon [icon]="icNotifications" inline="true" size="16px"></ic-icon>
                    <span class="box-text">Échéance :{{ card.deadlineDate | date}}</span>
                  </div>
                </div>

              </div>
            </div>
            <div class="mt-2 mr-8 action"
                 fxLayout="row"
                 fxLayoutGap="8px">
              <div *ngFor="let action of actions">
                <div class="box bg-app-bar text-secondary rounded caption" *ngIf="action.kanban"
                     [matTooltip]="action.label" (click)="doAction(action,card.details)">
                  <ic-icon [icIcon]="action.icon" class="ml-0" inline="true" size="20px"></ic-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vex-scrollbar>

    </div>
  </div>

</div>

