<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
  <h2
    class="headline m-0"
    fxFlex="auto">{{data.title}}</h2>

  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>
</div>

<mat-divider class="-mx-6 text-border"></mat-divider>

<mat-dialog-content fxLayout="column">
  <div *ngFor="let msg of data.messages" class="text-secondary message">
    {{msg}}
  </div>
</mat-dialog-content>
<mat-divider class="-mx-6 text-border"></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close type="button" *ngIf="!data.confirm">FERMER</button>
  <button mat-button mat-dialog-close type="button" *ngIf="data.confirm">NON</button>
  <button color="primary" mat-button type="submit" (click)="confirme()" *ngIf="data.confirm">OUI</button>
</mat-dialog-actions>

