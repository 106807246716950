<div (click)="$event.stopPropagation()" fxLayoutAlign="start center" fxLayoutGap="4px">
      <span *ngFor="let label of selectedLabels"
            [ngClass]="[label.textClass, label.bgClass]"
            class="rounded px-2 py-1 font-medium text-xs"
            fxFlex="none">
        {{ label.text }}
      </span>
  <div (click)="labelSelect.open()"
       class="text-secondary bg-base text-hint cursor-pointer hover:bg-hover"
       fxFlex="none"
       fxLayout="row"
       fxLayoutAlign="center center">
    <ic-icon [icon]="icAdd" size="18px"></ic-icon>
  </div>
  <mat-select #labelSelect="matSelect"
              (selectionChange)="onLabelChange($event, model)"
              [value]="data"
              class="invisible w-0 h-0 text-sm"
              fxFlex="0 1 0px"
              multiple>
    <mat-option *ngFor="let label of data" [value]="label">
      <div [ngClass]="label.previewClass"
           class="h-6 w-6 align-middle ltr:mr-2 rtl:ml-2 rounded inline-block"></div>
      <span>{{ label.text }}</span>
    </mat-option>
  </mat-select>
</div>
