import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { UntypedFormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { MatSelectChange } from "@angular/material/select";
import icPhone from '@iconify/icons-ic/twotone-phone';
import icMail from '@iconify/icons-ic/twotone-mail';
import icMap from '@iconify/icons-ic/twotone-map';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAdd from '@iconify/icons-ic/twotone-add';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import icFolder from '@iconify/icons-ic/twotone-folder';
import icDelete from '@iconify/icons-ic/twotone-delete';
import { DataService } from "../../services/data.service";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from "@angular/material/form-field";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { fadeInUp400ms } from "./animations/fade-in-up.animation";
import { stagger40ms } from "./animations/stagger.animation";
import { TableColumn } from "./interfaces/table-column.interface";
import { colors } from "@vought-app/data";

@UntilDestroy()
@Component({
  selector: 'vought-app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as unknown  as  MatFormFieldDefaultOptions
    }
  ]
})
export class DataTableComponent implements OnInit, AfterViewInit, OnChanges {
  layoutCtrl = new UntypedFormControl('fullwidth');
  @Input() canAdd: boolean= true;
  @Input() elements: Observable<any>;  // Changed to Observable<Pageable<any>>
  @Input() title: string;
  @Input() subTitle: string;
  @Output() itemEvent = new EventEmitter<any>();
  @Output() searchEvent = new EventEmitter<string>();
  @Input() columns: TableColumn<any>[];
  @Input() crumbs: string[];
  @Input() tags: any[];
  @Input() pageSize = 10;
  @Input() pageSizeOptions: number[] = [5, 10, 20, 50];
  @Input() selectionActions: any[];
  @Input() rowActions: any[];

  items: any[] = [];
  dataSource: MatTableDataSource<any> | null;
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new UntypedFormControl();
  totalElements: number;  
  totalPages: number;  
  pageIndex: number = 0;  

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private dataService: DataService) {}

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.elements.pipe(
      filter<any>(Boolean)
    ).subscribe(pageable => {
      this.items = pageable.content? pageable.content : [];
      this.dataSource.data = pageable.content? pageable.content : [];
      this.totalElements = pageable?.totalElements;
      this.totalPages = pageable?.totalPages;
      this.pageIndex = pageable?.pageable?.pageNumber;
    });
    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = new MatTableDataSource();
    this.elements.pipe(
      filter<any>(Boolean)
    ).subscribe(pageable => {
      this.items = pageable.content? pageable.content : [];
      this.dataSource.data = pageable.content? pageable.content : [];
      this.totalElements = pageable.totalElements;
      this.totalPages = pageable.totalPages;
      this.pageIndex = pageable.pageable?.pageNumber;
      this.paginator.length = pageable?.totalElements;
      this.paginator.pageIndex = pageable.pageable?.pageNumber;
      this.paginator.pageSize = pageable.pageable?.pageSize;
      this.paginator.pageSizeOptions = this.pageSizeOptions;

    });
  }

  ngAfterViewInit() {
   // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      this.updateTableParams();
    });
    this.sort.sortChange.pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      this.updateTableParams();
    });
  }

  updateTableParams(): any {
    const pageable: any = {
      page: this.paginator.pageIndex,
      size: this.paginator.pageSize,
      sort: this.sort.active,
      sortDirection: this.sort.direction
    };
    this.itemEvent.emit({
      action: 'pageableParams',
      data: pageable
    });
  }

  createItem() {
    this.itemEvent.emit({
      action: 'create'
    });
  }

  deleteItems(items: any[]) {
    this.itemEvent.emit({
      data: items,
      action: 'deleteList'
    });
  }

  updateItem(item: any) {
    this.itemEvent.emit({
      data: item,
      action: 'update'
    });
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    if (value.length < 2) {
      this.dataSource.filter = null;
      this.searchEvent.emit(null);
      return;
    }
    value = value.trim().toLowerCase();
    setTimeout(() => {
      this.dataSource.filter = value;
      this.searchEvent.emit(value);
    }, 200);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onTagChange(change: MatSelectChange, row: any) {
    const index = this.items.findIndex(c => c.id === row.id);
    this.items[index].tags = change.value;
    this.dataService.setData(this.items)
  }

  doAction(action: any, selected: any[]) {
    this.itemEvent.emit({
      data: selected,
      action: action.action
    });
  }

  get icPhone(): any {
    return icPhone;
  }

  get icMail(): any {
    return icMail;
  }

  get icMap(): any {
    return icMap;
  }

  get icSearch(): any {
    return icSearch;
  }

  get icAdd(): any {
    return icAdd;
  }

  get icFilterList(): any {
    return icFilterList;
  }

  get icDelete(): any {
    return icDelete;
  }

  get icMoreHoriz(): any {
    return icMoreHoriz;
  }

  get icFolder(): any {
    return icFolder;
  }

  getTag(value: any): any {
    const tag = this.tags.find(tg => tg.name === value);
    return tag ? tag : {}
  }

  getClassCss(id) {
    return colors[id || 0];
  }
}
