import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataTableComponent} from './components/data-table/data-table.component';
import {PageLayoutModule} from "../../../@vex/components/page-layout/page-layout.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {BreadcrumbsModule} from "../../../@vex/components/breadcrumbs/breadcrumbs.module";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {IconModule} from "@visurel/iconify-angular";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ContainerModule} from "../../../@vex/directives/container/container.module";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {KanbanBoardComponent} from './components/kanban-board/kanban-board.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatDividerModule} from "@angular/material/divider";
import {MatInputModule} from "@angular/material/input";
import {RelativeDateTimeModule} from "../../../@vex/pipes/relative-date-time/relative-date-time.module";
import {DateTokensModule} from "../../../@vex/pipes/date-tokens/date-tokens.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {ScrollbarModule} from "../../../@vex/components/scrollbar/scrollbar.module";
import {AutocompleteComponent} from './components/autocomplete/autocomplete.component';
import {MatTabsModule} from "@angular/material/tabs";
import {HighlightModule} from "../../../@vex/components/highlight/highlight.module";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {PopoverComponent} from "./components/kanban-board/components/popover/popover.component";
import {DataService} from "./services/data.service";
//import {CalendarModule} from "angular-calendar";
import { DataGridComponent } from './components/data-grid/data-grid.component';
import {RouterModule} from "@angular/router";
import { ElementCardComponent } from './components/data-grid/components/element-card/element-card.component';

@NgModule({
    imports: [
        CommonModule,
        PageLayoutModule,
        FlexLayoutModule,
        BreadcrumbsModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        IconModule,
        FormsModule,
        MatTooltipModule,
        ReactiveFormsModule,
        ContainerModule,
        MatSelectModule,
        MatButtonToggleModule,
        MatDialogModule,
        MatInputModule,
        MatDividerModule,
        RelativeDateTimeModule,
        DateTokensModule,
        DragDropModule,
        ScrollbarModule,
        MatTabsModule,
        HighlightModule,
        MatAutocompleteModule,
        
        RouterModule
    ],
  declarations: [
    DataTableComponent,
    KanbanBoardComponent,
    AutocompleteComponent,
    PopoverComponent,
    DataGridComponent,
    ElementCardComponent,
  ],
  exports: [
    DataTableComponent,
    KanbanBoardComponent,
    AutocompleteComponent,
    DataGridComponent,
    ElementCardComponent,
  ],
  providers:[DataService]
})
export class UiModule {
}
